<template>
  <div class="mt-1">
    <!-- Table Container Card -->
    <b-overlay :show="loading" rounded="sm" no-fade>
      <validation-observer ref="ticketRules" tag="form">
        <b-form @submit.prevent>
          <div>
            <b-card>
              <b-card>
                <span style="text-align: center;"><h3 class="mb-1">Shop Ticket</h3></span>

                <table class="custom-table mb-3" style="width:100%;">
                  <tr>
                    <td class="title">Job Number</td>
                    <td class="information">#{{ formData.id }}</td>
                  </tr>
                  <tr>
                    <td class="title">Customer</td>
                    <td class="information">{{ formData.contact }}</td>
                    <td class="title">Customer Email</td>
                    <td class="information">
                      {{ formData.contactEmail }}
                    </td>
                  </tr>

                  <tr>
                    <td class="title">Department:</td>
                    <td class="information">{{ formData.department }}</td>

                    <td class="title">Lab:</td>
                    <td class="information">{{ formData.lab }}</td>

                    <td class="title">P.I:</td>
                    <td class="information">{{ formData.principalInvestigator ? formData.principalInvestigator : 'N/A' }}</td>
                  </tr>
                </table>

                <b-card class="bg-info p-5" style="overflow:auto;" v-for="(part, partIndex) in formData.projectValues" :key="partIndex">
                  <input :ref="`refInputEl_${partIndex}`" class="d-none" type="file" accept="image/*" @input="imageSelected" />
                  <item-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />
                  <b-card-title class="mb-3 bigTitle">Part #{{ part.partNumber }} - Part Name:{{ part.partName }}</b-card-title>
                  <b-row>
                    <b-col cols="12" sm="3" md="3" xl="3">
                      <b-form-group label="Want By" label-for="madeBefore">
                        <span class="mt-3"> {{ part.wantBy }} </span>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="3" md="3" xl="3">
                      <b-form-group label="Need By" label-for="madeBefore">
                        <span class="mt-3"> {{ part.needBy }} </span>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="6" md="6" xl="6">
                      <b-form-group label="Assigned To" label-for="madeBefore">
                        <validation-provider name="Assigned To" #default="{ errors }" rules="required">
                          <v-select v-model="part.assignedTo" :options="dropEmployees" @input="updateFormData" label="value" input-id="customer-data" :clearable="false"> </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" class="mt-3 mb-3">
                      <b-row>
                        <div class="col-md-12">
                          <label>Notes</label>
                          <b-form-textarea v-model="part.notes" placeholder="Add Part Notes" maxlength="1220" rows="4" style="width: 100%;"></b-form-textarea>
                          <small v-if="part.notes">{{ 1220 - (part.notes ? part.notes.length : 0) > 0 ? 1220 - (part.notes ? part.notes.length : 0) : 0 }} characters remaining</small>
                        </div>
                      </b-row>
                    </b-col>

                    <b-col cols="12" sm="3" md="3" xl="3">
                      <b-form-group label="OV Process Required?" label-for="ovProcess">
                        <div class="d-flex">
                          <b-form-checkbox v-model="part.ovProcess" @change="updateFormData" name="ovProcess" value="Yes" class="mr-2">Yes</b-form-checkbox>
                          <b-form-checkbox v-model="part.ovProcess" @change="updateFormData" name="ovProcess" value="No">No</b-form-checkbox>
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" sm="9" md="9" xl="9">
                      <b-form-group label="Material Status" label-for="madeBefore">
                        <div class="d-flex">
                          <b-form-checkbox v-model="part.materialStatus" name="madeBefore" @change="updateFormData" value="Todo" class="mr-2">To do</b-form-checkbox>
                          <b-form-checkbox v-model="part.materialStatus" name="madeBefore" @change="updateFormData" value="Ordered" class="mr-2">Ordered</b-form-checkbox>
                          <b-form-checkbox v-model="part.materialStatus" name="madeBefore" @change="updateFormData" value="In House" class="mr-2">In House</b-form-checkbox>
                          <b-form-checkbox v-model="part.materialStatus" name="madeBefore" @change="updateFormData" value="Prepped" class="mr-2">Prepped</b-form-checkbox>
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12" class="mt-3 mb-3">
                      <label>Photos </label>

                      <b-row>
                        <b-col class="text-left">
                          <span v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                            <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                          </span>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-container fluid class="p-2 bg-white" style="min-height:250px; width:100%; ">
                            <b-row v-if="part.images ? part.images.length : 0 > 0">
                              <b-col xs="12" sm="6" md="4" lg="3" class="mb-2 text-right" v-for="(image, index) in part.images" :key="index">
                                <feather-icon icon="XIcon" v-b-tooltip.hover.top="'Delete'" size="20" class="deleteButton text-danger" @click="imageDelete(partIndex, index)" />

                                <b-media-aside @dblclick="imageClick(image)">
                                  <b-img-lazy thumbnail fluid v-bind="mainProps" ref="refPreviewEl" :src="image" />
                                </b-media-aside>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-button size="sm" class="ml-3 uploadButton" @click="selectFile(partIndex)" variant="primary">
                                Upload
                              </b-button>
                            </b-row>
                          </b-container>

                          <!-- <img src="https://api.harvard.systemstailormade.com/storage/app/public/images/170688681839138f92-1a35-3e41-9e12-cc87b5760640.png" height="100px" width="200px"/> -->
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-card>
            </b-card>

            <b-row v-if="formData != null" style="height: 150px;">
              <b-col class="text-right">
                <b-button v-if="formData.id != null" @click="createShopTicket" variant="primary">
                  Download
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import projectStoreModule from './projectStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import axiosIns from '@/libs/axios';

import ItemCropper from '@core/components/image-cropper/ItemCropper.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
    flatPickr,
    ItemCropper,
  },

  setup() {
    const FORM_APP_STORE_MODULE_NAME = 'project';
    // Register module
    if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, projectStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    var objectCodes = [
      {
        code: '6600',
        description: 'Laboratory Supplies+Materials, GENERAL',
      },
      {
        code: '8250',
        description: 'Technical Services, GENERAL',
      },
      {
        code: '6811',
        description: 'Non-Sponsored Work in Progress^Equip >=$5000',
      },
      {
        code: '6812',
        description: 'Sponsored Work in Progress^Equip >=$5000',
      },
    ];

    return {
      imageUrl: store.state.app.baseURL + 'images/none.jpg',
      tempImage: null,
      profileURL: store.state.app.baseURL + 'images/',
      selectedImage: null,
      croppedImage: null,
      percentCompleted: 0,
      profileFile: null,
      selectedSubItem: null,
      mainProps: {
        blankColor: '#bbb',
        width: 250,
        height: 250,
      },

      dropEmployees: [],
      projectId: null,
      formData: {
        created_at: null,
        id: null,
        contactEmail: null,
        contact: null,
        companyAddress: null,
        contactPhone: null,
        department: null,
        lab: null,
        digCode: null,
        type: 'internal',
        principalInvestigator: null,
        misc: null,
        projectName: null,
        maxCharacters: 1220, // Maximum allowed characters

        projectValues: [
          {
            partName: null,
            qty: null,
            partNumber: null,
            revision: null,
            material: null,
            materialName: null,
            madeBefore: null,
            wantBy: null,
            needBy: null,
            ovProcess: null,
            x: null,
            y: null,
            z: null,
          },
        ],
        notes: null,
      },
      objectCodes,
      tubCode: null,
      orgCode: null,
      objectCode: null,
      fundCode: null,
      activityCode: null,
      subActivityCode: null,
      rootCode: null,
      loading: true,
      baseURL: store.state.app.baseURL,
      materials: [],
    };
  },

  watch: {},

  methods: {
    truncateFileName(fileName, maxLength) {
      const truncatedName = fileName.length > maxLength ? fileName.slice(0, maxLength).toLowerCase() + '...' : fileName.toLowerCase();
      return truncatedName;
    },

    changeMaterial(part) {
      let newMaterial = this.materials.find((e) => e.id == part.material);
      part.materialName = newMaterial['name'];
    },

    generateQuote() {
      this.$router.push({ name: 'generate-quote' });
    },

    submitForm() {
      this.loading = true;
      this.$refs.contactRules.validate().then((success) => {
        if (success) {
          this.formData.projectValues.forEach((project) => {
            project.materialName = this.findMaterialAdditionals(project.material)?.name || '';
          });
          this.saveForm();
        } else {
          this.loading = false;
          this.$swal({
            icon: 'warning',
            title: 'Please try again.',
            text: 'Please fill in the required fields.',
          });
        }
      });
    },

    saveForm() {
      if (this.formData.type == 'internal') {
        this.formData.digCode = this.tubCode + this.orgCode + this.objectCode + this.fundCode + this.activityCode + this.subActivityCode + this.rootCode;
      }
      store
        .dispatch('project/saveForm', this.formData)
        .then((res) => {
          const applicationId = res.data.id;

          if (!applicationId) {
            this.loading = false;
            this.$swal({
              icon: 'error',
              title: 'An Error Occurred',
              text: 'An error occurred. Please try again.',
            });
            return;
          }

          this.uploadFiles(applicationId);
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            icon: 'error',
            title: 'An Error Occurred',
            text: 'An error occurred. Please try again.',
          });
        });
    },

    updateForm() {
      if (this.formData.type == 'internal') {
        this.formData.digCode = this.tubCode + this.orgCode + this.objectCode + this.fundCode + this.activityCode + this.subActivityCode + this.rootCode;
      }
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to update the project data?', {
          size: 'sm',
          title: 'Update Project',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;

            store
              .dispatch('project/updateForm', this.formData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Project has been updated successfully',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
                this.uploadFiles(this.formData.id);
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
    },

    imageClick(url) {
      this.$swal({
        imageUrl: url,
        imageWidth: 1000,
        imageHeight: 600,
        showConfirmButton: false,
        width: '1000px',
      });
    },

    selectFile(index) {
      this.$refs[`refInputEl_${index}`][0].value = null;
      this.selectedSubItem = index;
      this.$refs[`refInputEl_${index}`][0].click();
    },

    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null;

      if (file != null) {
        this.getBase64(file).then((data) => {
          this.selectedImage = data;
        });
      }
    },
    imageCropped(croppedImage) {
      if (croppedImage != null) {
        // this.$emit('update:formShow', true);
        let formData = new FormData();
        formData.append('file', croppedImage);
        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
        };
        axiosIns
          .post('cropProfilePhoto', formData, config)
          .then((response) => {
            this.imageUrl = this.profileURL + response.data;
            var images = this.formData.projectValues.find((e, index) => index == this.selectedSubItem).images;
            images.push(this.imageUrl);
            this.updateFormData();

            this.tempImage = null;
            this.selectedImage = null;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Successful',
                text: '✔️ Image Cropped Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            console.log(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Not Successful',
                text: '⛔ Image Upload Not Successful',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.tempImage = null;
        this.selectedImage = null;
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    getForms() {
      this.loading = true;

      this.projectId = parseInt(router.currentRoute.params.id);

      store
        .dispatch('project/getFormById', { id: router.currentRoute.params.id })
        .then((res) => {
          this.formData = res.data;
          this.formData.projectValues.forEach((element) => {
            if (typeof element.images == 'undefined') {
              element.images = [];
            }
            if (typeof element.materialStatus == 'undefined') {
              element.materialStatus = 'Todo';
            }
            if (typeof element.notes == 'undefined') {
              element.notes = null;
            }
          });

          if(this.formData.digCode){
            this.tubCode = this.formData.digCode.substring(0, 3) || null;
          this.orgCode = this.formData.digCode.substring(3, 8) || null;
          this.objectCode = this.formData.digCode.substring(8, 12) || null;
          this.fundCode = this.formData.digCode.substring(12, 18) || null;
          this.activityCode = this.formData.digCode.substring(18, 24) || null;
          this.subActivityCode = this.formData.digCode.substring(24, 28) || null;
          this.rootCode = this.formData.digCode.substring(28, 33) || null;
          }
          else{
            this.tubCode =  null;
          this.orgCode = null;
          this.objectCode = null;
          this.fundCode =  null;
          this.activityCode =  null;
          this.subActivityCode =  null;
          this.rootCode =  null;
          }
        
          this.getFormData();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    getFormData() {
      store
        .dispatch('project/getFormData')
        .then((res) => {
          this.materials = res.data.materials;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    downloadFile(fileId, fileName) {
      const swalWithLoading = this.$swal.mixin({
        title: 'Downloading...',
        text: 'Please wait while the file is being downloaded.',
        onOpen: () => {
          swalWithLoading.showLoading();

          store
            .dispatch('project/formFileDownload', { id: fileId })
            .then((res) => {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;

              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();

              this.simulateFileDownload();
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred',
                  text: 'Please try again later or contact support.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
            });
        },
      });

      swalWithLoading.fire();
    },

    simulateFileDownload() {
      setTimeout(() => {
        this.$swal('Download Completed!', 'Your file has been successfully downloaded.', 'success');
      }, 200);
    },

    imageDelete(partIndex, item) {
      this.formData.projectValues[partIndex].images.splice(item, 1);
      this.updateFormData();
    },

    getUsersDropdown() {
      store
        .dispatch('project/getUsersDropdown')
        .then((res) => {
          this.dropEmployees = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    createShopTicket() {
      this.updateFormData();
      this.$refs.ticketRules.validate().then((success) => {
        if (success) {
          const swalWithLoading = this.$swal.mixin({
            title: 'Downloading...',
            text: 'Please wait while the file is being downloaded.',
            onOpen: () => {
              swalWithLoading.showLoading();

              store
                .dispatch('project/createShopTicket', { id: this.formData.id })
                .then((res) => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;

                  link.setAttribute('download', 'Ticket_' + this.formData.id + '.pdf');
                  document.body.appendChild(link);
                  link.click();

                  this.simulateFileDownload();
                })
                .catch((error) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'An error occurred',
                      text: 'Please try again later or contact support.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                });
            },
          });

          swalWithLoading.fire();
        } else {
          this.$swal({
            title: 'Error',
            text: 'Process Failed. Please assign the parts to users.  ',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
          });
        }
      });
    },

    updateFormData() {
      store
        .dispatch('project/updateAssigneeList', this.formData)
        .then((response) => {
          this.getForms();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
    simulateFileDownload() {
      setTimeout(() => {
        this.$swal.fire('Download Completed!', 'Your file has been successfully downloaded.', 'success');
      }, 200);
    },
  },

  created() {
    if (router.currentRoute.params.id) {
      this.getForms();
    } else {
      this.getFormData();
    }

    this.getUsersDropdown();
  },

  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 10px;
}

.remove-part-button {
  position: absolute;
  top: 3px;
  right: 10px;
  background-color: #a51c30;
  border: none;
  color: white;
  font-size: 11px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.file-preview {
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.file-image {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  max-width: 100%;
}
.file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.remove-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: transparent;
  border: none;
  color: white;
  background-color: #a51c30;
  font-size: 0.8rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.file-preview:hover .remove-button {
  opacity: 1;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.title {
  font-weight: 600;
}

.border {
  padding: 0.5rem;
}

.bigTitle {
  color: #a51c30;

  font-size: 1.5rem;
}
// text-center mt-5 p-1 bg-light-secondary
.add-new-line-button {
  // text-align: center;
  margin-top: 2rem;
  // height: 3rem;
  // border: 1px solid #a51c3050;
  // border-style: dashed;
  // cursor: pointer;

  outline: none;
  display: inline-flex;

  line-height: 1.5;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-box-align: center;
  align-items: center;
  border: 1px dashed #a51c3080;
  height: 3rem;
  padding: 0px 1.5rem;
  width: 100%;
  background: rgb(247, 250, 255);
  color: #a51c30;
}

.add-new-line-button:hover {
  background: rgb(243, 245, 247);
  color: rgb(97, 113, 131);
}

.quoteInput {
  border: none;
  background-color: #ffffff;
  border-bottom: 1px solid lightgray;
}

.form-control:focus {
  border-color: #a51c30 !important;
}

.form-control {
  padding: 0px;
}

#quil-content ::v-deep {
  > .ql-container {
    .ql-editor {
      height: 300px !important;
    }
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    background-color: white;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  + #comment-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.prependIcon {
  border: none;
  font-size: 1.2rem;
  margin-right: 0px;
  padding-right: 3px;
  // margin-top: 2px;

  border-bottom: 1px solid lightgray;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.deleteButton {
  position: absolute;
  top: 1px;
  right: 25px;
  margin: 2px;
  padding: 3px;
  border-radius: 6px;
  color: #fd0000;
  cursor: pointer;
  opacity: 0.6;
}
.deleteButton:hover {
  opacity: 1;
}

.uploadButton {
  position: absolute;
  bottom: 5px;
  right: 15px;
  border-radius: 6px;
}
</style>
